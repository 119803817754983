import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const Complate: React.FC = () => {
  return (
    <>
      <h1>登録完了</h1>
      <p>ご貴重なお時間を頂きまして誠に有り難う御座いました。<br></br>以下に弊社の受信用メールアドレスをご連絡させて頂きます。</p>
      <p>是非今後とも情報交換等を通じまして、<br></br>まずは1件ご成約できるよう、尽力致したく存じます。</p>
      <p>今後とも末永いお付き合いの程、何卒宜しくお願い申し上げます。</p>
      <div>
        <h2><span style={{ marginRight: "10px", marginTop: "10px" }}><InfoOutlinedIcon fontSize="large" style={{ color: "blue" }}/></span>弊社受信用メールアドレス</h2>
        <h3>案件情報受信用メールアドレス: <a href="mailto:sales@d-make.co.jp">sales@d-make.co.jp</a></h3>
        <h3>エンジニア人材情報受信用メールアドレス: <a href="mailto:engineer@d-make.co.jp">engineer@d-make.co.jp</a></h3>
        ※案件情報とのメールの投げ分けが難しい場合は案件情報受信用メールアドレス(<a href="mailto:sales@d-make.co.jp">sales@d-make.co.jp</a>)と宛に配信をお願いします。
      </div>
    </>
  )
}

export default Complate