import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as gqlMutations from './graphql/mutations';
import * as gqlQueries from './graphql/queries';
import { useLocation } from 'react-router-dom';
import format from 'date-fns/format';
import awsExports from './aws-exports';
import './InputForm.css';
import Complate from './Complate';

Amplify.configure(awsExports);

// フォームの型
interface FormInputs {
  corpName: string;
  corpNumber: string;
  mailAddress1: string;
  mailAddress2: string;
  mailAddress3: string;
  hpUrl: string;
}

// バリデーションルール
const schema = yup.object({
  corpName: yup
    .string()
    .required('⚠ 必須項目です'),
  corpNumber: yup
    .string()
    .required('⚠ 必須項目です')
    .matches(/^[0-9]+$/, '⚠ 数字を入力してください'),
  mailAddress1: yup
    .string()
    .required('⚠ 必須項目です')
    .email('⚠ 正しいメールアドレスを入力してください'),
  mailAddress2: yup
    .string()
    .email('⚠ 正しいメールアドレスを入力してください'),
  mailAddress3: yup
    .string()
    .email('⚠ 正しいメールアドレスを入力してください'),
    hpUrl: yup
    .string()
    .url('⚠ 正しい形式でURLを入力してください'),
})

// 流入タイプ
enum InflowType {
  corporate = 'corporate',
  engineer = 'engineer',
}

const InputForm: React.FC = () => {
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [isFaild, setIsFaild] = useState<string>("")
  const [isFaildCorpName, setIsFaildCorpName] = useState<string>("")
  const [isFaildCorpNumber, setIsFaildCorpNumber] = useState<string>("")
  const [isFaildMailAddress1, setIsFaildMailAddress1] = useState<string>("")
  const [isFaildMailAddress2, setIsFaildMailAddress2] = useState<string>("")
  const [isFaildMailAddress3, setIsFaildMailAddress3] = useState<string>("")
  const [isFaildHpUrl, setIsFaildHpUrl] = useState<string>("")
	const query = new URLSearchParams(useLocation().search);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // バリデーションチェックOKなときに行う処理を追加
    let corpId = "";
		const now = new Date();
    const queryParam = query.get('iex');
    setIsFaild("");
    setIsFaildCorpName("");
    setIsFaildCorpNumber("");
    setIsFaildMailAddress1("");
    setIsFaildMailAddress2("");
    setIsFaildMailAddress3("");
    setIsFaildHpUrl("");
    setIsComplete(false);

    // Lambdaでパラメータチェック
    const lambdaParamCheckResult = await API.graphql(
      graphqlOperation(gqlQueries.infoExchangeParamCheckFunction, {
        corpName: data.corpName,              // 法人名
        corpNumber: data.corpNumber,          // 法人番号
        mailAddress1: data.mailAddress1,      // メールアドレス①
        mailAddress2: data.mailAddress2,      // メールアドレス②
        mailAddress3: data.mailAddress3,      // メールアドレス③
        hpUrl: data.hpUrl,                    // ホームページURL
      })
    );
    if ("data" in lambdaParamCheckResult && lambdaParamCheckResult.data) {
      // @ts-ignore
      const result = lambdaParamCheckResult.data.infoExchangeParamCheckFunction;
      if ("error" in JSON.parse(result) && "message" in JSON.parse(result)) {
        // パラメータチェック処理が失敗した場合
        // バリデーションエラー
        if ("corpName" in JSON.parse(result).message) {
          setIsFaildCorpName(JSON.parse(result).message.corpName[0]);
        }
        if ("corpNumber" in JSON.parse(result).message) {
          setIsFaildCorpNumber(JSON.parse(result).message.corpNumber[0]);
        }
        if ("mailAddress1" in JSON.parse(result).message) {
          setIsFaildMailAddress1(JSON.parse(result).message.mailAddress1[0]);
        }
        if ("mailAddress2" in JSON.parse(result).message) {
          setIsFaildMailAddress2(JSON.parse(result).message.mailAddress2[0]);
        }
        if ("mailAddress3" in JSON.parse(result).message) {
          setIsFaildMailAddress3(JSON.parse(result).message.mailAddress3[0]);
        }
        if ("hpUrl" in JSON.parse(result).message) {
          setIsFaildCorpNumber(JSON.parse(result).message.corpNumber[0]);
        }
        return
      }
      if ("error" in JSON.parse(result)) {
        // パラメータチェック処理が失敗した場合
        // その他エラー
        if (JSON.parse(result).error !== "validationError") {
          setIsFaild(JSON.parse(result).error);
        }
        return
      }
    }

    // Lambdaでデータ登録
    const lambdaRegisterResult = await API.graphql(
      graphqlOperation(gqlMutations.infoExchangeRegisterFunction, {
        corpName: data.corpName,              // 法人名
        corpNumber: data.corpNumber,          // 法人番号
        mailAddress1: data.mailAddress1,      // メールアドレス①
        mailAddress2: data.mailAddress2,      // メールアドレス②
        mailAddress3: data.mailAddress3,      // メールアドレス③
        hpUrl: data.hpUrl,                    // ホームページURL
      })
    );
    if ("data" in lambdaRegisterResult && lambdaRegisterResult.data) {
      // @ts-ignore
      const result = lambdaRegisterResult.data.infoExchangeRegisterFunction;
      corpId = JSON.parse(result).corpId
      if (Number.isNaN(parseInt(corpId))) {
        // 登録処理が失敗した場合
        setIsFaild(JSON.parse(result).error);
        return
      }
    }

    try {
      if (queryParam != null){
        // DynamoDBにデータを登録
        await API.graphql(
          graphqlOperation(gqlMutations.createInfoExchangeInflowData, {
            input: {
              CorporateNumber: data.corpNumber,             // 法人番号
              IexParameter: queryParam,                     // 流入パラメータ
              InflowType: InflowType.corporate,             // 流入タイプ
              CreatedAt: format(now, 'yyyyMMddHHmmSS'),     // 登録日時(yyyyMMddHHmmSS)
              Unixtime: Math.floor(now.getTime() / 1000),   // 登録日時(Unix秒)
            }
          })
        );
      }
    } catch (err) {
      // DynamoDB登録失敗用rollback処理
      await API.graphql(
        graphqlOperation(gqlMutations.infoExchangeRollbackFunction, {
          corpId: corpId,                       // 企業ID
          mailAddress1: data.mailAddress1,      // メールアドレス①
          mailAddress2: data.mailAddress2,      // メールアドレス②
          mailAddress3: data.mailAddress3,      // メールアドレス③
        })
      );
      setIsFaild("システムエラーが発生しました");
      return
    }
    // mattermostへ通知
    const queryParamCheck = queryParam != null ? queryParam : "なし";
    await API.graphql(
      graphqlOperation(gqlMutations.infoExchangeNotificationFunction, {
        corpId: corpId,           // 企業ID
        corpName: data.corpName,  // 法人名
        iex: queryParamCheck,     // 流入パラメータ
      })
    );
    setIsComplete(true);
  }
  if (!isComplete){
    return (
      <Container maxWidth="md" sx={{ pt: 5 }}>
        <Stack spacing={3}>
          <h1>法人情報入力</h1>
          {isFaild !== "" && (
            <div className="errorMessages">
              {isFaild}
            </div>
          )}
          {isFaildCorpName !== "" && (
            <div className="errorMessages">
              {isFaildCorpName}
            </div>
          )}
          {isFaildCorpNumber !== "" && (
            <div className="errorMessages">
              {isFaildCorpNumber}
            </div>
          )}
          {isFaildMailAddress1 !== "" && (
            <div className="errorMessages">
              {isFaildMailAddress1}
            </div>
          )}
          {isFaildMailAddress2 !== "" && (
            <div className="errorMessages">
              {isFaildMailAddress2}
            </div>
          )}
          {isFaildMailAddress3 !== "" && (
            <div className="errorMessages">
              {isFaildMailAddress3}
            </div>
          )}
          {isFaildHpUrl !== "" && (
            <div className="errorMessages">
              {isFaildHpUrl}
            </div>
          )}
          <TextField
            required
            label="法人名"
            fullWidth
            {...register('corpName')}
            error={'corpName' in errors}
            helperText={errors.corpName?.message}
          />
          <div>
            <Stack direction="row" spacing={3}>
              <TextField
                required
                label="法人番号"
                {...register('corpNumber')}
                error={'corpNumber' in errors}
                helperText={errors.corpNumber?.message}
              />
              <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" rel="noopener noreferrer">法人番号公表サイト(外部リンク)</a>
            </Stack>
          </div>
          <div style={{whiteSpace: 'pre-line'}}>{"弊社からの案件情報・エンジニア人材情報の受信用メールアドレス\n※３つまで受信用メールアドレスが登録可能です。4つ以上メールアドレスを指定した場合は弊社までお問い合わせください。"}</div>
          <TextField
            required
            label="メールアドレス（半角英数）"
            type="email"
            {...register('mailAddress1')}
            error={'mailAddress1' in errors}
            helperText={errors.mailAddress1?.message}
          />
          <TextField
            label="メールアドレス（半角英数）"
            type="email"
            {...register('mailAddress2')}
            error={'mailAddress2' in errors}
            helperText={errors.mailAddress2?.message}
          />
          <TextField
            label="メールアドレス（半角英数）"
            type="email"
            {...register('mailAddress3')}
            error={'mailAddress3' in errors}
            helperText={errors.mailAddress3?.message}
          />
          <TextField
            label="ホームページURL"
            type="text"
            {...register('hpUrl')}
            error={'hpUrl' in errors}
            helperText={errors.hpUrl?.message}
          />
          <p><strong>弊社の受信用メールアドレスは登録申請後の登録完了画面にてお知らせいたします。</strong></p>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
          >
            登録申請する
          </Button>
        </Stack>
      </Container>
    )
  }else{
    return (
      <Container maxWidth="md" sx={{ pt: 5 }}>
        <Complate></Complate>
      </Container>
    )
  }
}

export default InputForm
