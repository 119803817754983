import { memo, VFC } from "react";
import { Route, Switch } from "react-router-dom";
import { useTracking } from "../hooks/useTracking";
import InputForm from "../InputForm";

export const Router: VFC = memo(() => {
  useTracking('G-LPGVY24Q0D')

  return (
    <>
      <Switch>
        <Route exact path="/" component={InputForm} />
      </Switch>
    </>
  );
});