/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const infoExchangeParamCheckFunction = /* GraphQL */ `query InfoExchangeParamCheckFunction(
  $corpName: String!
  $corpNumber: String!
  $mailAddress1: String!
  $mailAddress2: String
  $mailAddress3: String
  $hpUrl: String
) {
  infoExchangeParamCheckFunction(
    corpName: $corpName
    corpNumber: $corpNumber
    mailAddress1: $mailAddress1
    mailAddress2: $mailAddress2
    mailAddress3: $mailAddress3
    hpUrl: $hpUrl
  )
}
` as GeneratedQuery<
  APITypes.InfoExchangeParamCheckFunctionQueryVariables,
  APITypes.InfoExchangeParamCheckFunctionQuery
>;
export const getInfoExchangeInflowData = /* GraphQL */ `query GetInfoExchangeInflowData(
  $CorporateNumber: String!
  $IexParameter: String!
) {
  getInfoExchangeInflowData(
    CorporateNumber: $CorporateNumber
    IexParameter: $IexParameter
  ) {
    CorporateNumber
    IexParameter
    InflowType
    CreatedAt
    Unixtime
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInfoExchangeInflowDataQueryVariables,
  APITypes.GetInfoExchangeInflowDataQuery
>;
export const listInfoExchangeInflowDatas = /* GraphQL */ `query ListInfoExchangeInflowDatas(
  $CorporateNumber: String
  $IexParameter: ModelStringKeyConditionInput
  $filter: ModelInfoExchangeInflowDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInfoExchangeInflowDatas(
    CorporateNumber: $CorporateNumber
    IexParameter: $IexParameter
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      CorporateNumber
      IexParameter
      InflowType
      CreatedAt
      Unixtime
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInfoExchangeInflowDatasQueryVariables,
  APITypes.ListInfoExchangeInflowDatasQuery
>;
