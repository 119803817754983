/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const infoExchangeRegisterFunction = /* GraphQL */ `mutation InfoExchangeRegisterFunction(
  $corpName: String!
  $corpNumber: String!
  $mailAddress1: String!
  $mailAddress2: String
  $mailAddress3: String
  $hpUrl: String
) {
  infoExchangeRegisterFunction(
    corpName: $corpName
    corpNumber: $corpNumber
    mailAddress1: $mailAddress1
    mailAddress2: $mailAddress2
    mailAddress3: $mailAddress3
    hpUrl: $hpUrl
  )
}
` as GeneratedMutation<
  APITypes.InfoExchangeRegisterFunctionMutationVariables,
  APITypes.InfoExchangeRegisterFunctionMutation
>;
export const infoExchangeRollbackFunction = /* GraphQL */ `mutation InfoExchangeRollbackFunction(
  $corpId: Int!
  $mailAddress1: String!
  $mailAddress2: String
  $mailAddress3: String
) {
  infoExchangeRollbackFunction(
    corpId: $corpId
    mailAddress1: $mailAddress1
    mailAddress2: $mailAddress2
    mailAddress3: $mailAddress3
  )
}
` as GeneratedMutation<
  APITypes.InfoExchangeRollbackFunctionMutationVariables,
  APITypes.InfoExchangeRollbackFunctionMutation
>;
export const infoExchangeNotificationFunction = /* GraphQL */ `mutation InfoExchangeNotificationFunction(
  $iex: String!
  $corpName: String!
  $corpId: String!
) {
  infoExchangeNotificationFunction(
    iex: $iex
    corpName: $corpName
    corpId: $corpId
  )
}
` as GeneratedMutation<
  APITypes.InfoExchangeNotificationFunctionMutationVariables,
  APITypes.InfoExchangeNotificationFunctionMutation
>;
export const createInfoExchangeInflowData = /* GraphQL */ `mutation CreateInfoExchangeInflowData(
  $input: CreateInfoExchangeInflowDataInput!
  $condition: ModelInfoExchangeInflowDataConditionInput
) {
  createInfoExchangeInflowData(input: $input, condition: $condition) {
    CorporateNumber
    IexParameter
    InflowType
    CreatedAt
    Unixtime
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInfoExchangeInflowDataMutationVariables,
  APITypes.CreateInfoExchangeInflowDataMutation
>;
